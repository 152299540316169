<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>
    <div v-else class="layout1 py-4">
      <div class="container my-4">
        <div style=" position:relative; " class="row">
          <div class="col-12 col-sm-8 section-left m-auto">

            <div class="StepRow StepRow--s mb-3">
              <div class="rc-radios rc-radios--theme-default rc-radios--accent vertical" data-testid="ServiceTypeControl">
                <div v-for="assignmentType in asignmentFormTypes" :key="assignmentType" class="radio-button-wrapper">
                  <button @click="changeAssignmentTypeForm(assignmentType)"  :class="orderForm.type == assignmentType.type ? 'active' : '' " type="button" class="radio-button radio-button--n-37 p-3  " tabindex="-1" data-testid="radio-button">
                    <div class="button-checkbox">

                    </div>
                    <div class="radio-button__main-content-wrapper d-flex align-items-start flex-column justify-content-center ">
                      <div style="font-size: 18px;" class="radio-button__title text-left mb-1 font-weight-bold"> {{ assignmentType.title || '' }}</div>
                      <div class="radio-button__description text-left "> {{ assignmentType.desc || "" }} </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <!-- nav tabs -->
            <ul
              class="
                nav nav-pills
                mb-3
                nav-justified
                border-1 border-primary
                rounded
                shadow
              "
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link rounded font-weight-bold cursor-pointer"
                  :class="step == 1 ? 'active' : ''"
                  id="paper-details-tab"
                  :aria-selected="step == 1 ? true : false"
                  @click="step = 1"
                  >1. Paper Details</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link rounded font-weight-bold cursor-pointer"
                  :class="step == 2 ? 'active' : ''"
                  id="order-preferences-tab"
                  :aria-selected="step == 2 ? true : false"
                  @click="nextStep(2)"
                  >2. Order Preferences</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link rounded font-weight-bold cursor-pointer"
                  :class="step == 3 ? 'active' : ''"
                  id="account-tab"
                  :aria-selected="step == 3 ? true : false"
                  @click="nextStep(3)"
                  >3. Account Details</a
                >
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade" 
                :class="step == 1 ? 'show active' : ''"
                id="paper-details"
                role="tabpanel"
                aria-labelledby="paper-details-tab"
                
              >
                <div class="border-1 border rounded p-2">
                  <!-- step1 -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      ></label
                    >
                    <div class="col-sm-9">
                      <div class="rc-validation-representer valid" data-invalid="false">
                      <div v-if="orderForm.type != 'programming' && orderForm.type != 'calculations' " class=" scroll-container " ref="scrollContainer">
                        <div  class="rc-radios rc-radios--theme-default vertical-on-small ServiceTypeControl  "  data-testid="serviceTypeControl">
                          <div v-for="formType in orderFormTypes" :key="formType" class="radio-button-wrapper radio-button-wrapper--flexbuttons radio-button-wrapper--same-width">
                            <button @click="changeOrderTypeForm(formType)"  type="button" class="radio-button radio-button--n-37 " :class="orderForm.type == formType.type ? 'active' : '' "  tabindex="-1" data-testid="radio-button">
                              <div class="button-checkbox">
                                
                              </div>
                              <div class="ServiceTypeControl-button ServiceTypeControl-button--academic-writing">
                                <div class="ServiceTypeControl-button__title">{{formType.title}}</div>
                                <div class="ServiceTypeControl-button__subTitle">{{formType.desc}}</div>
                              </div>
                            </button>
                          </div>
                        </div>

                      </div>
                      <div v-if="orderForm_type == 'Shadow Health' " class=" scroll-container mt-2 " ref="scrollContainer">
                        <div  class="rc-radios rc-radios--theme-default vertical-on-small ServiceTypeControl  "  data-testid="serviceTypeControl">
                          
                            <div
                              class="
                                btn-group
                                atbd-button-group
                                btn-group-normal
                                my-2
                                overflow-auto
                              "
                              role="group"
                            >
                            <!-- {{ orderForm.academic_level }} -->
                              <button
                                style="min-width: 240px !important; line-height:1.3em!important; "
                                v-for="(shadow, index) in ShadowHealthTypes"
                                :key="index + 'om'"
                                type="button"
                                class="col-3 py-2 px-1 d-flex align-items-center justify-content-center btn-sm btn-outline-light radio-button radio-button--n-1"
                                :class="
                                  orderForm.type == shadow.type
                                    ? 'active'
                                    : ''
                                "
                                @click="activateShadowHealth(shadow)"
                              >
                                {{ shadow.desc }}
                              </button>
                            </div>
                          <!-- </div> -->
                        </div>
                      </div>

                      </div>
                    </div>
                  </div>


                  <div v-if="orderForm.type == 'programming'" >
                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="col-sm-3 col-form-label text-right"
                        >Deadline / Urgency</label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="title"
                          v-model="orderForm.special_urgency"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="Delivery_Time"
                          :reduce="(doc) => doc.value"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends urgency  -->

                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Programming language </label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="title"
                          v-model="orderForm.programming_language"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="programmingLanguages"
                          :reduce="(doc) => doc.title"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends type of programmingLanguages  -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        " 
                        >Category</label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="title"
                          v-model="orderForm.programming_category"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="programmingCategory"
                          :reduce="(area) => area.title"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends programmingCategory -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Order Instructions
                        </label
                      >
                      <div class="col-sm-9">
                        <vue-editor
                          :class="!!errors.description ? 'invalid' : ''"
                          v-model="orderForm.description"
                        ></vue-editor>
                        <div class="d-flex flex-row align-items-baseline">
                          <!-- <textarea
                            :class="!!errors.description ? 'invalid' : ''"
                            rows="4"
                            v-model="orderForm.description"
                            class="form-control"
                            id="inputEmail3"
                            placeholder="Paste your order/paper/assignment instructions"
                          ></textarea> -->
                          <BasePopover>
                            Your personal information (Phone Number, Email
                            Address) is always kept private and we won't share
                            it–just don't include it in your order details.
                          </BasePopover>
                        </div>
                        <p
                          class="text-danger text-left my-1"
                          v-if="'description' in errors"
                        >
                          {{ errors.description }}
                        </p>
                      </div>
                    </div>
                    <!-- ends description -->

                    <!-- ends title -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Order Files</label
                      >
                      <div class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>

                        <!-- <div class="file-upload-container">
                          <input
                            type="file"
                            @change="uploadFiles"
                            @dragend="uploadFiles"
                            class="form-control border-0 file-upload"
                            id="inputEmail3"
                            placeholder="Choose files to upload"
                            multiple="multiple"
                          />

                          <div
                            class="mb-1 mx-1"
                            v-if="orderForm.files.length > 0"
                          >
                            <i
                              v-for="(file, index) in orderForm.files"
                              :key="index"
                            >
                              <span
                                @click="deleteFile(file)"
                                class="text-danger m-1 cursor-pointer delete-file"
                                ><span class="icon-trash"></span
                              ></span>
                              {{ file.name }}
                              <br />
                            </i>
                          </div>
                        </div> -->
                      </div>
                      <!-- <div class="col-12 col-sm-9 offset-sm-3">
                        <p class="m-1 text-warning text-left">
                          <span class="icon-help"></span> Drag and drop files or
                          click to select files to upload
                        </p>
                      </div> -->
                    </div>




                  </div>

                  <div v-if="orderForm.type == 'calculations'" >
                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="col-sm-3 col-form-label text-right"
                        >Deadline / Urgency</label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="title"
                          v-model="orderForm.special_urgency"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="Delivery_Time"
                          :reduce="(doc) => doc.value"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends urgency  -->

                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Discipline </label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="title"
                          v-model="orderForm.programming_language"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="programmingLanguages"
                          :reduce="(doc) => doc.title"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends type of programmingLanguages  -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        " 
                        >Software</label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="title"
                          v-model="orderForm.programming_category"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="programmingCategory"
                          :reduce="(area) => area.title"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends programmingCategory -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Order Instructions
                        </label
                      >
                      <div class="col-sm-9">
                        <vue-editor
                          :class="!!errors.description ? 'invalid' : ''"
                          v-model="orderForm.description"
                        ></vue-editor>
                        <div class="d-flex flex-row align-items-baseline">
                          <!-- <textarea
                            :class="!!errors.description ? 'invalid' : ''"
                            rows="4"
                            v-model="orderForm.description"
                            class="form-control"
                            id="inputEmail3"
                            placeholder="Paste your order/paper/assignment instructions"
                          ></textarea> -->
                          <BasePopover>
                            Your personal information (Phone Number, Email
                            Address) is always kept private and we won't share
                            it–just don't include it in your order details.
                          </BasePopover>
                        </div>
                        <p
                          class="text-danger text-left my-1"
                          v-if="'description' in errors"
                        >
                          {{ errors.description }}
                        </p>
                      </div>
                    </div>
                    <!-- ends description -->

                    <!-- ends title -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Order Files</label
                      >
                      <div class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                      </div>
                    </div>




                  </div>

                  <div v-if="orderForm.type == 'order'" >

                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Type of Document</label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="desc"
                          v-model="orderForm.document_type"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="document_types"
                          :reduce="(doc) => doc.id"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends type of document  -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        " 
                        >Subject Area</label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="desc"
                          v-model="orderForm.subject_area"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="subject_areas"
                          :reduce="(area) => area.id"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends subject area -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Academic Level</label
                      >
                      <div class="col-sm-9 text-left">
                        <div
                          class="btn-group overflow-auto pb-2"
                          v-if="academic_levels.length > 0"
                        >
                          <button
                            type="button"
                            class="btn"
                            v-for="(level, index) in academic_levels"
                            :key="index + 'al'"
                            :class="
                              orderForm.academic_level === level.id
                                ? 'btn-primary'
                                : 'border-1 border-muted'
                            "
                            @click="activateAcademicLevel(level)"
                          >
                            {{ level.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- ends academic leve -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Title <span class="text-danger">*</span></label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.title"
                            class="form-control text-left"
                            :class="!!errors.title ? 'invalid' : ''"
                            id="inputEmail3"
                            required
                          />
                          <BasePopover>
                            Should not be less than 7 characters. (A unique
                            identifier for easy order tracking. E.g Assignment
                            1034)
                          </BasePopover>
                        </div>
                        <p
                          class="text-danger text-left my-1"
                          v-if="'title' in errors"
                        >
                          {{ errors.title }}
                        </p>
                      </div>
                    </div>
                    <!-- ends title -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Order Instructions
                        <span class="text-danger">*</span></label
                      >
                      <div class="col-sm-9">
                        <vue-editor
                          :class="!!errors.description ? 'invalid' : ''"
                          v-model="orderForm.description"
                        ></vue-editor>
                        <div class="d-flex flex-row align-items-baseline">
                          <!-- <textarea
                            :class="!!errors.description ? 'invalid' : ''"
                            rows="4"
                            v-model="orderForm.description"
                            class="form-control"
                            id="inputEmail3"
                            placeholder="Paste your order/paper/assignment instructions"
                          ></textarea> -->
                          <BasePopover>
                            Your personal information (Phone Number, Email
                            Address) is always kept private and we won't share
                            it–just don't include it in your order details.
                          </BasePopover>
                        </div>
                        <p
                          class="text-danger text-left my-1"
                          v-if="'description' in errors"
                        >
                          {{ errors.description }}
                        </p>
                        <!-- <p class="mb-1 text-warning text-left">
                          <span class="icon-help"></span> Your personal
                          information (Phone Number, Email Address) is always kept
                          private and we won't share it–just don't include it in
                          your order details.
                        </p> -->
                      </div>
                    </div>
                    <!-- ends description -->

                    <!-- ends title -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Order Files</label
                      >
                      <div class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>

                        <!-- <div class="file-upload-container">
                          <input
                            type="file"
                            @change="uploadFiles"
                            @dragend="uploadFiles"
                            class="form-control border-0 file-upload"
                            id="inputEmail3"
                            placeholder="Choose files to upload"
                            multiple="multiple"
                          />

                          <div
                            class="mb-1 mx-1"
                            v-if="orderForm.files.length > 0"
                          >
                            <i
                              v-for="(file, index) in orderForm.files"
                              :key="index"
                            >
                              <span
                                @click="deleteFile(file)"
                                class="text-danger m-1 cursor-pointer delete-file"
                                ><span class="icon-trash"></span
                              ></span>
                              {{ file.name }}
                              <br />
                            </i>
                          </div>
                        </div> -->
                      </div>
                      <!-- <div class="col-12 col-sm-9 offset-sm-3">
                        <p class="m-1 text-warning text-left">
                          <span class="icon-help"></span> Drag and drop files or
                          click to select files to upload
                        </p>
                      </div> -->
                    </div>

                    <!-- ends file upload -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >State</label
                      >
                      <div class="col-sm-9 d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.state"
                          class="form-control"
                          id="inputEmail3"
                        />
                        <BasePopover>Applies to US citizens</BasePopover>
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Nursing Speciality</label
                      >
                      <div class="col-sm-9 d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.speciality"
                          class="form-control"
                          id="inputEmail3"
                        />
                        <BasePopover>Applies to nursing writing</BasePopover>
                      </div>
                    </div>
                    <!-- ends nursing speciality and state -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Paper format/Style</label
                      >
                      <div class="col-sm-9 text-left">
                        <div
                          class="btn-group overflow-auto"
                          v-if="styles.length > 0"
                        >
                          <button
                            type="button"
                            class="btn"
                            v-for="(style, index) in styles"
                            :key="index + 'al'"
                            :class="
                              orderForm.style === style.id
                                ? 'btn-primary'
                                : 'border-1 border-muted'
                            "
                            @click="activateAcademicStyle(style)"
                          >
                            {{ style.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- ends paper format type -->
                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Language Style</label
                      >
                      <div class="col-sm-9 text-left">
                        <div
                          class="btn-group overflow-auto"
                          v-if="language_styles.length > 0"
                        >
                          <button
                            type="button"
                            class="btn"
                            v-for="(style, index) in language_styles"
                            :key="index"
                            :class="
                              orderForm.language_style === style.id
                                ? 'btn-primary'
                                : 'border-1 border-muted'
                            "
                            @click="activateLanguageStyle(style)"
                          >
                            {{ style.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- ends language style -->

                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="col-sm-3 col-form-label text-right"
                        >Deadline / Urgency</label
                      >
                      <div class="col-sm-9">
                        <vue-select
                          label="desc"
                          v-model="orderForm.urgency"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="urgency"
                          :reduce="(urg) => urg.id"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- ends urgency  -->
                    <div class="is-flex">
                      <div class="form-group row">
                        <label
                          class="
                            col-form-label
                            py-0
                            col-3
                            pt-0
                            text-left text-sm-right
                          "
                          >No of Pages/Words</label
                        >
                        <div class="col-9 d-flex flex-row align-items-baseline">
                          <div>
                            <div class="input-group">
                              <span class="input-group-prepend">
                                <button
                                  class="btn btn-outline-primary btn-icon"
                                  type="button"
                                  @click="updatePages(0)"
                                >
                                  <i class="icon-minus3"></i>
                                </button>
                              </span>
                              <input
                                type="number"
                                @change="calculatePrice"
                                v-model="orderForm.pages"
                                name="pages"
                                style="min-width: 72px"
                                min="0"
                                :max="number_of_pages.max_pages"
                                class="form-control text-center"
                                placeholder
                              />
                              <span class="input-group-append">
                                <button
                                  class="btn btn-outline-primary btn-icon"
                                  type="button"
                                  @click="updatePages(1)"
                                >
                                  <i class="icon-plus3"></i>
                                </button>
                              </span>
                            </div>
                            <strong class="mt-1"
                              >{{ no_of_words || "275" }} words</strong
                            >
                          </div>
                          <!-- help tooltip -->
                          <BasePopover>
                            Please choose how many pages you need. 1 double spaced
                            page is approximately 275 words, 1 single spaced page
                            is approximately 550 words. Title and bibliography
                            pages are included for free.
                          </BasePopover>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          class="
                            col-form-label
                            py-0
                            col-3
                            pt-0
                            text-left text-sm-right
                          "
                          >Spacing</label
                        >
                        <div class="col-9 text-left">
                          <div class="btn-group" v-if="spacing.length > 0">
                            <button
                              type="button"
                              class="btn"
                              v-for="(space, index) in spacing"
                              :key="index + 'sp'"
                              :class="
                                orderForm.spacing === space.id
                                  ? 'btn-primary'
                                  : 'border-1 border-muted'
                              "
                              @click="activateSpacing(space)"
                            >
                              {{ space.desc }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ends pages -->
                    <div class="row">
                      <div class="col-12 col-md-3 offset-md-3">
                        <div class="form-group row">
                          <label
                            class="
                              col-form-label
                              py-0
                              col-3 col-sm-12
                              pt-0
                              text-left
                            "
                            >Charts:</label
                          >
                          <div class="col-8 col-sm-12">
                            <div class="input-group">
                              <span class="input-group-prepend">
                                <button
                                  class="btn btn-outline-primary btn-icon"
                                  type="button"
                                  @click="updateCharts(0)"
                                >
                                  <i class="icon-minus3"></i>
                                </button>
                              </span>
                              <input
                                @change="calculatePrice"
                                type="number"
                                v-model="orderForm.charts"
                                name="charts"
                                min="1"
                                class="form-control text-center"
                                placeholder
                              />
                              <span class="input-group-append">
                                <button
                                  class="btn btn-outline-primary btn-icon"
                                  type="button"
                                  @click="updateCharts(1)"
                                >
                                  <i class="icon-plus3"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group row">
                          <label
                            class="
                              col-form-label
                              py-0
                              col-3 col-sm-12
                              pt-0
                              text-left
                            "
                            >PowerPoint slides:</label
                          >
                          <div class="col-8 col-sm-12">
                            <div class="input-group">
                              <span class="input-group-prepend">
                                <button
                                  class="btn btn-outline-primary btn-icon"
                                  type="button"
                                  @click="updateSlides(0)"
                                >
                                  <i class="icon-minus3"></i>
                                </button>
                              </span>
                              <input
                                @change="calculatePrice"
                                type="number"
                                v-model="orderForm.slides"
                                name="slides"
                                min="1"
                                class="form-control text-center"
                                placeholder
                              />
                              <span class="input-group-append">
                                <button
                                  class="btn btn-outline-primary btn-icon"
                                  type="button"
                                  @click="updateSlides(1)"
                                >
                                  <i class="icon-plus3"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- ends slides and presentations -->
                      <div class="col-12 col-md-3">
                        <div class="form-group row">
                          <label
                            class="
                              col-form-label
                              py-0
                              col-3 col-sm-12
                              pt-0
                              text-left
                            "
                            >Sources to be cited:</label
                          >
                          <div class="col-8 col-sm-12">
                            <div class="input-group">
                              <span class="input-group-prepend">
                                <button
                                  class="btn btn-outline-primary btn-icon"
                                  type="button"
                                  @click="decrementSources"
                                >
                                  <i class="icon-minus3"></i>
                                </button>
                              </span>
                              <input
                                type="number"
                                v-model="orderForm.no_sources"
                                name="no_sources"
                                min="0"
                                class="form-control text-center"
                                placeholder
                              />
                              <span class="input-group-append">
                                <button
                                  class="btn btn-outline-primary btn-icon"
                                  type="button"
                                  @click="orderForm.no_sources++"
                                >
                                  <i class="icon-plus3"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div v-if="orderForm.type == 'exam'" >
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Type of Exams </label
                      >
                      <div class="col-sm-9">
                          <vue-select
                          label="title"
                          v-model="orderForm.description_extras.type"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="Type_of_paper"
                          :reduce="(doc) => doc.value"
                        ></vue-select>
                          
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Academic Level</label
                      >
                      <div class="col-sm-9 text-left">
                        <div
                          class="btn-group overflow-auto pb-2"
                          v-if="academic_levels.length > 0"
                        >
                          <button
                            type="button"
                            class="btn"
                            v-for="(level, index) in academic_levels"
                            :key="index + 'al'"
                            :class="
                              orderForm.academic_level === level.id
                                ? 'btn-primary'
                                : 'border-1 border-muted'
                            "
                            @click="activateAcademicLevel(level)"
                          >
                            {{ level.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- ends academic leve -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Course code </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.course_code"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Course code"
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Course name </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.course_name"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Course name "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >University</label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.university"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter University"
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Delivery Time </label
                      >
                      <div class="col-sm-9">
                          <vue-select
                          label="title"
                          v-model="orderForm.special_urgency"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="Delivery_Time"
                          :reduce="(doc) => doc.value"
                        ></vue-select>
                          
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Upload Documents</label
                      >
                      <div class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Email/username </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.school_email_username"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Email/username "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Password </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.school_password"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Password "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Comments </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <textarea
                            rows="4"
                            v-model="orderForm.description_extras.comments"
                            class="form-control"
                            id="inputEmail3"
                            placeholder="Please share any additional comments here..."
                          ></textarea>
                        </div>
                        
                      </div>
                    </div>

                    <!-- ends title -->
                  </div>

                  <div v-if="orderForm.type == 'quiz'" >
                    
                    
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Course code </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.course_code"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Course code"
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Course name </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.course_name"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Course name "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >University</label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.university"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter University"
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Academic Level</label
                      >
                      <div class="col-sm-9 text-left">
                        <div
                          class="btn-group overflow-auto pb-2"
                          v-if="academic_levels.length > 0"
                        >
                          <button
                            type="button"
                            class="btn"
                            v-for="(level, index) in academic_levels"
                            :key="index + 'al'"
                            :class="
                              orderForm.academic_level === level.id
                                ? 'btn-primary'
                                : 'border-1 border-muted'
                            "
                            @click="activateAcademicLevel(level)"
                          >
                            {{ level.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- ends academic leve -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Delivery Time </label
                      >
                      <div class="col-sm-9">
                          <vue-select
                          label="title"
                          v-model="orderForm.special_urgency"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="Delivery_Time"
                          :reduce="(doc) => doc.value"
                        ></vue-select>
                          
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Upload Documents</label
                      >
                      <div class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Email/username </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.school_email_username"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Email/username "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Password </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.school_password"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Password "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Comments </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <textarea
                            rows="4"
                            v-model="orderForm.description_extras.comments"
                            class="form-control"
                            id="inputEmail3"
                            placeholder="Please share any additional comments here..."
                          ></textarea>
                        </div>
                        
                      </div>
                    </div>

                    <!-- ends title -->
                  </div>

                  <div v-if="orderForm.type == 'ihuman'" >
                    
                    
                    <div class="form-group row mb-2">
                      <label
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Case Study name  </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.title"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Give name of the case study ( eg Tina Jones Health History)"
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Delivery Time </label
                      >
                      <div class="col-sm-9">
                          <vue-select
                          label="title"
                          v-model="orderForm.special_urgency"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="Delivery_Time"
                          :reduce="(doc) => doc.value"
                        ></vue-select>
                          
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Academic Level</label
                      >
                      <div class="col-sm-9 text-left">
                        <div
                          class="btn-group overflow-auto pb-2"
                          v-if="academic_levels.length > 0"
                        >
                          <button
                            type="button"
                            class="btn"
                            v-for="(level, index) in academic_levels"
                            :key="index + 'al'"
                            :class="
                              orderForm.academic_level === level.id
                                ? 'btn-primary'
                                : 'border-1 border-muted'
                            "
                            @click="activateAcademicLevel(level)"
                          >
                            {{ level.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- ends academic leve -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Upload Documents</label
                      >
                      <div class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                      </div>
                    </div>

                    <!-- ends title -->
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Email/username </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.school_email_username"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Email/username "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Password </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.school_password"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Password "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Comments </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <textarea
                            rows="4"
                            v-model="orderForm.description_extras.comments"
                            class="form-control"
                            id="inputEmail3"
                            placeholder="Please share any additional comments here..."
                          ></textarea>
                        </div>
                        
                      </div>
                    </div>
                  </div>

                  <div v-if="orderForm.type == 'shadow_soap' || orderForm.type == 'shadow' " >
                    
                    
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Case Study name  </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.title"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Give name of the case study ( eg Tina Jones Health History)"
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Delivery Time </label
                      >
                      <div class="col-sm-9">
                          <vue-select
                          label="title"
                          v-model="orderForm.special_urgency"
                          placeholder="Start typing to search"
                          @input="calculatePrice"
                          :options="Delivery_Time"
                          :reduce="(doc) => doc.value"
                        ></vue-select>
                          
                      </div>
                    </div>
                    
                    <div v-if="orderForm.type != 'shadow' " class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Upload Template</label
                      >
                      <div class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                      </div>
                    </div>

                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Academic Level</label
                      >
                      <div class="col-sm-9 text-left">
                        <div
                          class="btn-group overflow-auto pb-2"
                          v-if="academic_levels.length > 0"
                        >
                          <button
                            type="button"
                            class="btn"
                            v-for="(level, index) in academic_levels"
                            :key="index + 'al'"
                            :class="
                              orderForm.academic_level === level.id
                                ? 'btn-primary'
                                : 'border-1 border-muted'
                            "
                            @click="activateAcademicLevel(level)"
                          >
                            {{ level.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- ends academic leve -->

                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Email/username </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.school_email_username"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Email/username "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Password </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <input
                            type="text"
                            v-model="orderForm.description_extras.school_password"
                            class="form-control text-left"
                            id="inputEmail3"
                            placeholder="Enter Password "
                            required
                          />
                        </div>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label
                        for="inputEmail3"
                        class="
                          col-sm-3 col-form-label
                          py-0
                          text-left text-sm-right
                        "
                        >Comments </label
                      >
                      <div class="col-sm-9">
                        <div class="d-flex flex-row align-items-baseline">
                          <textarea
                            rows="4"
                            v-model="orderForm.description_extras.comments"
                            class="form-control"
                            id="inputEmail3"
                            placeholder="Please share any additional comments here..."
                          ></textarea>
                        </div>
                        
                      </div>
                    </div>

                    <!-- ends title -->
                  </div>
                  <!-- ends pages slides and PowerPoint -->
                  <!-- totals and next -->
                  <div class="form-group row">
                    <!-- <label
                      for="inputEmail3"
                      class="
                        col-2 col-sm-3 col-form-label py-0
                        text-left text-sm-right
                        font-weight-bold
                      "
                      >Total price:</label
                    >
                    <div class="col-5 col-sm-3">
                      <h1 class="font-weight-bold text-left">${{ "0.0" }}</h1>
                    </div> -->
                    <div class="col-12 col-sm-6 text-left offset-sm-3">
                      <button
                        class="
                          btn btn-warning btn-block
                          rounded
                          btn-lg
                          px-5
                          py-2
                          font-weight-bold
                          nav-link
                        "
                        @click="nextStep(2)"
                      >
                        NEXT STEP
                      </button>
                    </div>
                  </div>
                  <!-- ends totals and next -->
                  <!-- ./step1 -->
                </div>
              </div>
              <!-- ends step 1 -->
              <div
                class="tab-pane fade"
                :class="step == 2 ? 'show active' : ''"
                id="order-preferences"
                role="tabpanel"
                aria-labelledby="order-preferences-tab"
              >
                <div class="border-1 border-muted rounded p-2 p-sm-3 mb-3">
                  <div v-if="orderForm.type != 'programming' && orderForm.type != 'calculations'" class="form-group row">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Writer preferences</label
                    >
                    <div class="col-sm-9">
                      <div
                        class="preferences row"
                        v-if="writer_levels.length > 0"
                      >
                        <div
                          class="col-12 col-sm-4"
                          v-for="(pref, index) in writer_levels"
                          :key="index + 'wp'"
                        >
                          <button
                            type="button"
                            class="btn preference border-1"
                            :class="
                              orderForm.writer_level === pref.id
                                ? 'selected border-primary'
                                : 'default border-muted'
                            "
                            @click="activateWriterPref(pref)"
                          >
                            <div style="vertical-align: top !important">
                              <div
                                v-show="orderForm.writer_level === pref.id"
                                class="
                                  badge badge-primary
                                  rounded-circle
                                  selected
                                "
                                style="padding: 6px 6px"
                              >
                                <span class="icon-check"></span>
                              </div>
                              <h4 class="text-success font-weight-bold mb-1">
                                {{ `${pref.cost}` || "" }}
                              </h4>
                              <p class="my-1 font-weight-bold">
                                {{ pref.title }}
                                <br />
                              </p>
                              <p
                                class="my-0 text-capitalize"
                                style="
                                  white-space: normal;
                                  word-wrap: break-word;
                                "
                              >
                                {{ pref.desc }}
                              </p>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="orderForm.type == 'programming' " class="form-group row">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Extras</label
                    >
                    <div class="col-sm-9">
                      <div
                        class="preferences row"
                        v-if="ProgrammingTypes.length > 0"
                      >
                        <div
                          class="col-12 col-sm-6"
                          v-for="(pref, index) in ProgrammingTypes"
                          :key="index + 'wp'"
                        >
                          <button
                            type="button"
                            class="btn preference border-1"
                            :class="
                              orderForm.extras.includes(pref.type)  ? 'selected border-primary'
                                : 'default border-muted'
                            "
                            @click="activateExtras(pref)"
                          >
                            <div style="vertical-align: top !important">
                              <div
                                v-show="orderForm.extras.includes(pref.type)"
                                class="
                                  badge badge-primary
                                  rounded-circle
                                  selected
                                "
                                style="padding: 6px 6px"
                              >
                                <span class="icon-check"></span>
                              </div>
                              <!-- <h4 class="text-success font-weight-bold mb-1">
                                {{ `${pref.cost}` || "" }}
                              </h4> -->
                              <p class="my-1 font-weight-bold">
                                {{ pref.title }}
                                <br />
                              </p>
                              <p
                                class="my-0 text-capitalize"
                                style="
                                  white-space: normal;
                                  word-wrap: break-word;
                                "
                              >
                                {{ pref.desc }}
                              </p>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="orderForm.type == 'programming' || orderForm.type == 'calculations' " class="form-group row">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Task size</label
                    >
                    <div class="col-sm-9">

                      <div class="StepRow StepRow--s">
                        <div class="rc-validation-representer TaskSizeControl TaskSizeControlForCalculation valid" data-invalid="false">
                          <div  class="rc-radios rc-radios--theme-default vertical-on-small">
                            <div class="radio-button-wrapper ">
                              <button  @click="orderForm.task_size = '1'; calculatePrice() " :class="orderForm.task_size == '1' ? 'active' : '' " type="button" class="radio-button radio-button--n-5 " tabindex="0" data-testid="radio-button"><div class="button-checkbox"></div>
                                <div class="radio-button__main-content-wrapper py-2 ">
                                  <div class="radio-button__title">Extra small</div>
                                </div>
                              </button>
                            </div>

                            <div class="radio-button-wrapper ">
                              <button  @click="orderForm.task_size = '2'; calculatePrice()  " :class="orderForm.task_size == '2' ? 'active' : '' " type="button" class="radio-button radio-button--n-1 " tabindex="-1" data-testid="radio-button"><div class="button-checkbox"></div>
                                <div class="radio-button__main-content-wrapper">
                                  <div class="radio-button__title">Small</div>
                                </div>
                              </button>
                            </div>

                            <div class="radio-button-wrapper ">
                              <button  @click="orderForm.task_size = '3'; calculatePrice()  " :class="orderForm.task_size == '3' ? 'active' : '' " type="button" class="radio-button radio-button--n-2" tabindex="0" data-testid="radio-button"><div class="button-checkbox"></div>
                                <div class="radio-button__main-content-wrapper">
                                  <div class="radio-button__title">Medium</div>
                                </div>
                              </button>
                            </div>

                            <div class="radio-button-wrapper ">
                              <button  @click="orderForm.task_size = '4'; calculatePrice()  " :class="orderForm.task_size == '4' ? 'active' : '' " type="button" class="radio-button radio-button--n-3" tabindex="0" data-testid="radio-button"><div class="button-checkbox"></div>
                                <div class="radio-button__main-content-wrapper">
                                  <div class="radio-button__title">Large</div>
                                </div>
                              </button>
                            </div>
                          </div>


                          <div v-if="orderForm.type == 'calculations' " class="TaskSizeControl__descriptors">
                            <div class="TaskSizeControl__descriptors__example">
                              <div class="TaskSizeControl__descriptors__title">Example:</div>
                              <div v-show="orderForm.task_size == '1'" class="TaskSizeControl__descriptors__body">up to 3 short practice problems or theoretical questions</div>
                              <div v-show="orderForm.task_size == '2'" class="TaskSizeControl__descriptors__body">up to 7 short practice problems or theoretical questions</div>
                              <div v-show="orderForm.task_size == '3'" class="TaskSizeControl__descriptors__body">up to 15 practice problems or theoretical questions</div>
                              <div v-show="orderForm.task_size == '4'" class="TaskSizeControl__descriptors__body">moderately complex problem analysis project (using applied math methods)</div>
                            </div>
                            <div class="TaskSizeControl__descriptors__deliverables">
                              <div class="TaskSizeControl__descriptors__title">Possible deliverables:</div>
                              <ul v-show="orderForm.task_size == '1'" class="TaskSizeControl__deliverables">
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Simple computations</li>
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Short answers to questions</li>
                              </ul>
                              <ul v-show="orderForm.task_size == '2'" class="TaskSizeControl__deliverables">
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Simple computations</li>
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Simple charts and diagrams</li>
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Short answers to questions</li>
                              </ul>
                              <ul v-show="orderForm.task_size == '3'" class="TaskSizeControl__deliverables">
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Computations</li>
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Charts and diagrams</li>
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Detailed explanations</li>
                              </ul>
                              <ul v-show="orderForm.task_size == '4'" class="TaskSizeControl__deliverables">
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Computations</li>
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Charts and diagrams</li>
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Detailed report</li>
                                <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Presentation</li>
                              </ul>
                            </div>
                          </div>

                          <div v-if="orderForm.type == 'programming' " class="TaskSizeControl__descriptors">
                            <div class="TaskSizeControl__descriptors__example">
                              <div class="TaskSizeControl__descriptors__title">Example:</div>
                              <div v-show="orderForm.task_size == '1'" class="TaskSizeControl__descriptors__body">
                               <span>up to 3 short practice problems</span>
                               <ul class="TaskSizeControl__examples-list">
                                  <li>3 functions/outputs</li>
                                  <li>class (OOP) with 1-3 simple methods</li>
                                  <li>simple binary tree</li>
                                  <li>set of short practice tasks</li>
                                </ul>
                              </div>
                              <div v-show="orderForm.task_size == '2'" class="TaskSizeControl__descriptors__body">
                                <span>up to 7 short practice problems, a simple program with up to 7 functions</span>
                                <ul class="TaskSizeControl__examples-list"><li>small user interface</li><li>data visualization with 3-4 plots</li><li>simple decoder/encode</li><li>set of short practice tasks</li></ul>
                              
                              </div>

                              <div v-show="orderForm.task_size == '3'" class="TaskSizeControl__descriptors__body">
                                <span>up to 15 practice problems, a small lab report, well-defined project or a landing page</span>
                                <ul class="TaskSizeControl__examples-list"><li>data visualization up to 7 plots</li><li>small lab report</li><li>compiler prototype for a simple language</li><li>database with up to 7 tables</li><li>simple console game</li><li>simple algorithm for 1 scenario</li><li>simple model converted from a drawing</li><li>simple data analysis project on 1 dataset</li></ul>
                              </div>

                              <div v-show="orderForm.task_size == '4'" class="TaskSizeControl__descriptors__body">
                                <span>large programming tasks or projects, extensive lab report</span>
                                <ul class="TaskSizeControl__examples-list"><li>extensive lab report</li><li>simple game with UI</li><li>web-based product with 1-2 scenarios</li><li>application with 1-2 scenarios</li><li>API</li><li>responsive website of 2-5 pages</li><li>model development from scratch</li><li>data analysis project for a business problem based on 2-3 datasets</li></ul>
                              </div>
                            </div>
                            <div class="TaskSizeControl__descriptors__deliverables">
                              <div class="TaskSizeControl__descriptors__title">Possible deliverables:</div>
                              <ul v-show="orderForm.task_size == '1'" class="TaskSizeControl__deliverables"><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--source-code">Source code</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--screenshots-of-outputs">Screenshots of outputs</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Short answers to questions</li></ul>

                              <ul v-show="orderForm.task_size == '2'" class="TaskSizeControl__deliverables"><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--source-code">Source code</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--screenshots-of-outputs">Screenshots of outputs</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Short answers to questions</li></ul>

                              <ul v-show="orderForm.task_size == '3'" class="TaskSizeControl__deliverables"><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--source-code">Source code</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--screenshots-of-outputs">Screenshots of outputs</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Brief report</li></ul>

                              <ul v-show="orderForm.task_size == '4'" class="TaskSizeControl__deliverables"><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--source-code">Source code</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--screenshots-of-outputs">Screenshots of outputs</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--documentation">Documentation</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--detailed-report">Detailed report</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--presentation">Presentation</li></ul>

                            </div>
                          </div>



                        </div>
                      </div>


                    </div>
                  </div>



                  


                  <!-- ends preferences -->
                  <div v-if="additional_services.length > 0">
                    <div
                      class="form-group row mb-1"
                      v-for="(service, index) in additional_services"
                      :key="index"
                    >
                      <label
                        for="inputEmail3"
                        class="
                          col-4 col-sm-3 col-form-label
                          py-0
                          pt-2
                          text-right
                        "
                        >{{ service.title }}</label
                      >
                      <div class="col-8 col-sm-9 pb-0 mb-0">
                        <div class="additional-service text-left">
                          <div class="custom-control custom-checkbox mb-0">
                            <input
                              v-model="orderForm.additional_services"
                              @change="calculatePrice"
                              type="checkbox"
                              class="custom-control-input border-1"
                              :id="service.id"
                              :value="service.id"
                            />
                            <label
                              class="custom-control-label d-flex flex-row"
                              :for="service.id"
                            >
                              <h5
                                class="my-0 text-success font-weight-bold my-0"
                              >
                                + ${{ service.cost || 0 }}
                              </h5>
                              <BasePopover>{{ service.desc }}</BasePopover>
                            </label>
                          </div>
                          <!-- <p class="mt-0 mb-1"></p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ends additional_services -->
                  <div
                    class="form-group row prefered-writer"
                    v-if="isAuthenticated && Object.keys(authUser).length > 0"
                  >
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Preferred Writer <br />
                    </label>
                    <div class="col-sm-9">
                      <vue-select
                        v-model="orderForm.writer_id"
                        @input="calculatePrice"
                        placeholder="Start typing to search"
                        :options="writers"
                        :reduce="(writer) => writer.id"
                        label="first_name"
                      >
                        <template slot="option" slot-scope="option">
                          {{ option.first_name }}
                          <p class="is-rating-stars-1 d-flex mt-1 ml-1">
                            <span
                              v-for="(i, index) in 5"
                              :key="index + 'rating'"
                            >
                              <span
                                class="text-warning icon mt-1"
                                :class="
                                  option.rating.average_rating >= index + 1
                                    ? 'icon-star-full2'
                                    : 'icon-star-empty3'
                                "
                              ></span>
                            </span>
                            <span
                              style="
                                margin-left: 10px;
                                margin-top: 4px;
                                font-weight: bold;
                              "
                              >{{ option.rating.average_rating || "0" }}
                            </span>
                          </p>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.first_name }}
                          <p class="is-rating-stars-1 d-flex mt-1 ml-1">
                            <span
                              v-for="(i, index) in 5"
                              :key="index + 'rating'"
                            >
                              <span
                                class="text-warning icon mt-1"
                                :class="
                                  option.rating.average_rating >= index + 1
                                    ? 'icon-star-full2'
                                    : 'icon-star-empty3'
                                "
                              ></span>
                            </span>
                            <span
                              style="
                                margin-left: 10px;
                                margin-top: 4px;
                                font-weight: bold;
                              "
                              >{{ option.rating.average_rating || "0" }}
                            </span>
                          </p>
                        </template>
                      </vue-select>
                    </div>
                    <!-- {{writers}} -->
                    <!-- <div class="col-sm-2">
                        <button
                              class="btn btn-dark btn-icon"
                              type="button"
                              @click="orderForm.writer_id =  ''"
                            >
                              Remove Writer
                            </button>
                      </div> -->
                  </div>
                  <div class="form-group row" v-else>
                    <label
                      for="inputEmail3"
                      class="col-sm-3 col-form-label py-0 text-left text-sm-right"
                      >Writer Id</label
                    >
                    <div class="col-sm-9">
                      <input
                        type="numeric"
                        v-model="orderForm.writer_id"
                        class="form-control"
                        id="inputEmail3"
                        @input="calculatePrice"
                      />
                      <p class="my-1 text-left">
                        <span class="icon-help"></span> Enter your favourite
                        writer ID if you've had previous writers.
                      </p>
                    </div>
                  </div>

                  <!-- ends prefered writer -->
                  <!-- totals and next -->
                  <div class="form-group row">
                    <!-- <label
                      for="inputEmail3"
                      class="
                        col-2 col-sm-3 col-form-label py-0
                        text-left text-sm-right
                        font-weight-bold
                      "
                      >Total price:</label
                    >
                    <div class="col-5 col-sm-3">
                      <h1 class="font-weight-bold text-left">${{ "0.0" }}</h1>
                    </div> -->
                    <div class="col-12 col-sm-6 offset-sm-3 text-left">
                      <button
                        class="
                          btn btn-warning btn-block
                          rounded
                          btn-lg
                          px-5
                          py-2
                          font-weight-bold
                          nav-link
                        "
                        @click="step = 3"
                      >
                        NEXT STEP
                      </button>
                    </div>
                  </div>
                  <!-- ends totals and next -->
                </div>
              </div>
              <!-- ends step 2 -->
              <div
                class="tab-pane fade"
                :class="step == 3 ? 'show active' : ''"
                id="account"
                role="tabpanel"
                aria-labelledby="account-tab"
              >
                <!-- account -->
                <div class="border-1 border-muted rounded p-0">
                  <div
                    v-if="isAuthenticated && Object.keys(authUser).length > 0"
                  >
                    <div class="py-2">
                      <h5 class="my-0">
                        You are logged in as {{ authUser.email || "" }}
                      </h5>
                    </div>
                    <div class="px-3 py-2">
                      <div
                        class="rounded mb-1 py-1 bg-danger-300"
                        v-if="pages_message"
                      >
                        {{ pages_message || "" }}
                      </div>
                      <button
                        type="button"
                        @click="doPlaceOrder('checkout')"
                        class="btn btn-block btn-warning text-uppercase rounded"
                        :disabled="is_loading"
                      >
                        {{
                          is_loading ? "Loading.." : " Update Order"
                        }}
                      </button>
                    </div>
                  </div>
                  <div v-else class="is-account">
                    <ul
                      class="
                        nav nav-pills
                        mb-3
                        nav-justified
                        border-1 border-primary
                        rounded
                        shadow
                      "
                    >
                      <li class="nav-item">
                        <a
                          href="#bottom-divided-tab1"
                          class="nav-link active show"
                          data-toggle="tab"
                          @click="customer_type = 'new'"
                          >New Customer</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="#bottom-divided-tab2"
                          class="nav-link"
                          data-toggle="tab"
                          @click="customer_type = 'returning'"
                          >Returning Customer</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content py-2 px-3">
                      <!-- register tab -->
                      <div
                        class="tab-pane fade active show"
                        id="bottom-divided-tab1"
                      >
                        <form
                          class="flex-fill"
                          ref="registerForm"
                          @submit.prevent="doPlaceOrder('register')"
                        >
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >First & Last Names
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div
                                    class="
                                      form-group
                                      form-group-feedback
                                      form-group-feedback-right
                                    "
                                  >
                                    <input
                                      v-model="registerForm.first_name"
                                      name="first_name"
                                      required
                                      type="text"
                                      class="form-control"
                                      placeholder="First name"
                                    />
                                    <div class="form-control-feedback">
                                      <i class="icon-user-check text-muted"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div
                                    class="
                                      form-group
                                      form-group-feedback
                                      form-group-feedback-right
                                    "
                                  >
                                    <input
                                      v-model="registerForm.last_name"
                                      required
                                      name="last_name"
                                      type="text"
                                      class="form-control"
                                      placeholder="Second name"
                                    />
                                    <div class="form-control-feedback">
                                      <i class="icon-user-check text-muted"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <input
                                v-model="registerForm.email"
                                name="email"
                                type="email"
                                class="form-control"
                                placeholder="Email Address"
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Country <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <vue-select
                                required
                                label="name"
                                v-model="registerForm.country"
                                placeholder="Start typing to search"
                                :options="countryNames"
                                :reduce="(country) => country"
                              >
                              </vue-select>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Phone
                            </label>
                            <div class="col-sm-9 d-flex flex-row">
                              <div
                                class="
                                  country-flag
                                  rounded
                                  border-1 border-muted
                                  text-center
                                  px-2
                                  mr-2
                                  d-flex
                                  flex-row
                                  align-items-center align-content-center
                                  justify-content-center
                                "
                                style="width: 120px !important"
                              >
                                <span style="font-size: 22px">{{
                                  registerForm.country.emoji || ""
                                }}</span>
                                <p class="my-0 ml-1">
                                  + {{ registerForm.country.phone || "" }}
                                </p>
                              </div>
                              <input
                                v-model="registerForm.phone"
                                name="phone"
                                type="tel"
                                class="form-control"
                                placeholder="Your Phone"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Password
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div
                                    class="
                                      form-group
                                      form-group-feedback
                                      form-group-feedback-right
                                    "
                                  >
                                    <input
                                      v-model="registerForm.password"
                                      name="password"
                                      type="password"
                                      class="form-control"
                                      placeholder="Create password"
                                      required
                                    />
                                    <div class="form-control-feedback">
                                      <i class="icon-user-lock text-muted"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div
                                    class="
                                      form-group
                                      form-group-feedback
                                      form-group-feedback-right
                                    "
                                  >
                                    <input
                                      v-model="
                                        registerForm.password_confirmation
                                      "
                                      name="password_confirmation"
                                      type="password"
                                      class="form-control"
                                      placeholder="Repeat password"
                                      required
                                    />
                                    <div class="form-control-feedback">
                                      <i class="icon-user-lock text-muted"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- ends pages slides and PowerPoint -->
                          <!-- totals and next -->
                          <div class="form-group row">
                            <!-- <label
                              for="inputEmail3"
                              class="
                                col-2 col-sm-3 col-form-label py-0
                                text-left text-sm-right
                                font-weight-bold
                              "
                              >Total price:</label
                            >
                            <div class="col-5 col-sm-3">
                              <h1 class="font-weight-bold text-left">
                                ${{ "0.0" }}
                              </h1>
                            </div> -->
                            <div class="col-12 col-sm-6 offset-sm-3 text-left">
                              <button
                                class="
                                  btn btn-warning btn-block
                                  rounded
                                  btn-lg
                                  px-5
                                  py-2
                                  font-weight-bold
                                  text-uppercase
                                  nav-link
                                "
                                type="submit"
                                :disabled="is_loading"
                              >
                                {{
                                  is_loading
                                    ? "Loading.."
                                    : " Update Order"
                                }}
                              </button>
                            </div>
                          </div>
                          <!-- ends totals and next -->
                        </form>
                      </div>
                      <!-- /register tab -->
                      <!-- login tab -->
                      <div class="tab-pane fade" id="bottom-divided-tab2">
                        <form
                          class="is-login"
                          ref="loginForm"
                          @submit.prevent="doPlaceOrder('login')"
                        >
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <input
                                type="email"
                                v-model="loginForm.email"
                                class="form-control"
                                id="inputEmail3"
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Password
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <input
                                type="password"
                                v-model="loginForm.password"
                                class="form-control"
                                id="inputEmail3"
                                required
                              />
                            </div>
                          </div>
                          <!-- ends pages slides and PowerPoint -->
                          <!-- totals and next -->
                          <div class="form-group row">
                            <div class="col-12 col-sm-6 text-left offset-sm-3">
                              <button
                                class="
                                  btn btn-warning btn-block
                                  rounded
                                  btn-lg
                                  px-5
                                  py-2
                                  font-weight-bold
                                  nav-link
                                  text-uppercase
                                "
                                type="submit"
                                :disabled="is_loading"
                              >
                                {{
                                  is_loading
                                    ? "Loading.."
                                    : " Update Order"
                                }}
                              </button>
                            </div>
                          </div>
                          <!-- ends totals and next -->
                        </form>
                      </div>
                      <!-- /login tab -->
                    </div>
                  </div>
                </div>
                <!-- ends account -->
              </div>
              <!-- ends step 3 -->
            </div>
          </div>
          <div class="col-12 col-sm-4 text-right">
            <div style=" position:sticky; top:15px; " class="card px-0 pb-4 shadow rounded totals-summary">
              <div class="card-body">

                <!-- <h5 class="text-left " style="
                    font-weight: light;
                    font-style: normal;
                    font-size: 1.1rem;
                ">In our commitment to excellence, we take pride in our ability to provide the 
                finest solutions with utmost expediency. Our dedication to quality ensures that 
                your needs are not only met, but surpassed in the swiftest manner possible.
              <br><br>
              <span style="text-align: center;" class="text-danger">Request Your Quote Now!!</span>
            </h5> -->

            
                <h6 v-show="orderForm.type != 'programming'"  class="order-topic flex flex-row text-left">
                  <strong>Title: </strong>  <div v-html="orderForm.title" /> 
                </h6>
                <table
                  v-if="orderForm.type == 'order'"
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Academic level:</strong> {{ academic_level }}
                    </td>
                  </tr>
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Subject area: </strong>{{ subject_area }}
                    </td>
                  </tr>
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Type of paper:</strong> {{ document_type }}
                    </td>
                  </tr>
                </table>
                <table
                  v-else
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr>
                    <td v-if="orderForm.description_extras && orderForm.description_extras.course_code" class="order-short-info mb-0 pb-1">
                      <strong>Course Code:</strong> {{orderForm.description_extras.course_code}}
                    </td>
                  </tr>
                  <tr>
                    <td v-if="orderForm.description_extras && orderForm.description_extras.course_name"  class="order-short-info mb-0 pb-1">
                      <strong>Course Name: </strong>{{orderForm.description_extras.course_name}}
                    </td>
                  </tr>
                  <tr>
                    <td v-if="orderForm.description_extras && orderForm.description_extras.university" class="order-short-info mb-0 pb-1">
                      <strong>University:</strong> {{orderForm.description_extras.university}}
                    </td>
                  </tr>
                  <tr>
                    <td  class="order-short-info mb-0 pb-1">
                      <strong>Delivery Time:</strong> {{orderForm.special_urgency}} Days
                    </td>
                  </tr>
                </table>
                <hr class="mt-0" />
                <div class="d-flex align-items-center justify-content-start py-2 w-48">
                  <vue-select
                    label="currency"
                    class='w-48'
                    v-model="orderForm.currency"
                    placeholder="Start typing to search"
                    @input="calculatePrice"
                    :options="currencies"
                    :reduce="(area) => area.currency"
                  ></vue-select>
                </div>
                <table
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr
                    v-for="(price, index) in getPrice"
                    :key="index + 'test'"
                    :class="price.title == 'Total' ? 'total-label' : ''"
                  >
                    <td>{{ price.title || "" }}</td>
                    <td class="text-right text-black font-weight-bold price">
                      {{ price.value || "" }}
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <div
                        v-if="message"
                        class="alert my-1 rounded"
                        :class="message.type ? 'alert-success' : 'alert-danger'"
                      >
                        <span v-html="message.message || ''"></span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="card-footer px-3 pb-2">
                <div class="form-group mb-2 text-left">
                  <label for="inputEmail3" class="text-left">Coupon Code</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter coupon code for discounts"
                      v-model="orderForm.coupon_code"
                      class="form-control"
                      id="inputEmail3"
                      @input="calculatePrice"
                    />
                    <div
                      v-if="message"
                      class="alert my-1 rounded"
                      :class="message.type ? 'alert-success' : 'alert-danger'"
                    >
                      <span v-html="message.message || ''"></span>
                    </div>
                    <div v-if="coupon" class="alert alert-success rounded my-1">
                      <span v-html="coupon || ''"></span>
                    </div>
                  </div>
                </div>
                <div
                  class="rounded mb-1 py-1 bg-danger-300"
                  v-if="pages_message"
                >
                  {{ pages_message || "" }}
                </div>

                <button
                  v-if="step == 1 || step == 2"
                  type="button"
                  @click="nextStep(step + 1)"
                  class="btn btn-block btn-warning rounded shadow"
                >
                  Next Step
                </button>
                <button
                  v-else
                  type="button"
                  @click="doPlaceOrder('checkout')"
                  class="
                    btn btn-block btn-warning
                    rounded
                    shadow
                    text-uppercase
                  "
                  :disabled="is_loading"
                >
                  {{ is_loading ? "Loading.." : " Update Order" }}
                </button>
              </div>
               <div class="text-center">
                <img src="/mcafee.png" width="100px" alt="mcafee secure" />
                <!-- {{orderForm.type}} -->
                
              </div> 
            </div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { countries } from "countries-list";
import { mapGetters, mapActions, mapState } from "vuex";
import BasePopover from "../../components/_base-popover.vue";
import FileUploader from "../../components/formcontrols/FileUploader.vue";
export default {
  name: "PlaceOrder",
  components: { BasePopover, FileUploader },
  computed: {
    countryNames() {
      return Object.keys(countries).map((country) => countries[country]);
    },
    ...mapGetters("setups", [
      "academic_levels",
      "document_types",
      "language_styles",
      "number_of_pages",
      "spacing",
      "styles",
      "subject_areas",
      "urgency",
      "writer_levels",
      "additional_services",
      'currencies',
      "defaults",
    ]),
    ...mapGetters("auth", ["isAuthenticated", "authUser"]),
    ...mapGetters("order", ["getPrice"]),
    ...mapState("order", ["message", "coupon"]),
    ...mapState("writers", ["writers"]),
    ...mapState("order", ["order"]),

    // ...mapState("setups", ["countries"]),
    no_of_words() {
      return (
        275 *
          this.orderForm.pages *
          this.spacing && this.spacing?.find((x) => x.id == this.orderForm.spacing).factor || 1
      );
    },

    subject_area() {
      return (
        this.subject_areas && this.subject_areas?.find((x) => x.id == this.orderForm.subject_area)
          .desc || ""
      );
    },
    document_type() {
      return (
        this.document_types && this.document_types?.find((x) => x.id == this.orderForm.document_type)
          .desc || ""
      );
    },
    academic_level() {
      return (
        this.academic_levels && this.academic_levels?.find((x) => x.id == this.orderForm.academic_level)
          .desc || ""
      );
    },

    pages_message() {
      let message = "";
      if (
        this.orderForm.pages < 1 &&
        this.orderForm.charts < 1 &&
        this.orderForm.slides < 1
      ) {
        message = "You need to order at least 1 page, 1 slide or 1 chart";
      } else message = "";
      return message;
    },
  },
  data() {
    return {
      ordertypeTitle:'',
      orderForm_type:'order',
      step: 1,
      countries,
      assignment_type:'Academic writing',
      customer_type: "new",
      is_loading: false,
      orderForm: new this.$Form({
        academic_level: "",
        subject_area: "",
        currency: "",
        language_style: "",
        task_size:"1",
        extras:[],
        urgency: "",
        document_type: "",
        spacing: "",
        writer_level: "",
        special_urgency:"",
        order_id: null,
        type: "",
        style: 1,
        programming_language:"",
        programming_category:"",
        additional_services: [],
        pages: this.$route.query && this.$route.query.pages_calc ? this.$route.query.pages_calc :  1,
        no_sources: 0,
        charts: 0,
        slides: 0,
        title: "",
        description: "",
        state: "",
        speciality: "",
        files: [],
        writer_id: "",
        coupon_code: "",
        description_extras:{
          type:'',
          course_code:'',
          course_name:'',
          university:'',
          school_password:'',
          school_email_username:"",
          comments:'',

        },
      }),
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
      }),
      loginForm: new this.$Form({
        email: "",
        password: "",
      }),
      errors: {
        title: "",
        description: "",
      },

      orderFormTypes:[
        {
          title:'Academic writing',
          desc:'Custom Papers',
          type:'order',
        },
        {
          title:'Exam',
          desc:'Custom Exams',
          type:'exam',
        },
        {
          title:'Quiz',
          desc:'Custom Quiz',
          type:'quiz',
        },
        {
          title:'Shadow Health',
          desc:'Shadow Health',
          type:'shadow_soap',

        },
        {
          title:'iHuman ',
          desc:'Custom iHuman',
          type:'ihuman',

        },
        // {
        //   title:'Full Class',
        //   desc:'Custom Classes',
        //   type:'class',
        // },
      ],

      asignmentFormTypes:[
        {
          title:'Academic writing',
          desc:'Essay, homework, research paper, etc.',
          type:'order',
        },
        {
          title:'Programming',
          desc:'Coding, modeling, design, debugging, etc.',
          type:'programming',
        },
        {
          title:'Calculations',
          desc:'Problems, equations, computations, etc.',
          type:'calculations',
        },
        // {
        //   title:'Full Class',
        //   desc:'Custom Classes',
        //   type:'class',
        // },
      ],
      programmingLanguages:[
        {
          title:'Java',
        },
        {
          title:'Javascript',
        },
        {
          title:'Python',
        },
        {
          title:'C',
        },
        {
          title:'C++',
        },
        {
          title:'C#',
        },
        {
          title:'R/R Studio',
        },
        {
          title:'SQL',
        },
        {
          title:'Matlab',
        },
        {
          title:'Excel',
        },
        {
          title:'HTML',
        },
        {
          title:'Other(set your custom option)',
        },
      ],

      programmingCategory:[
        {
          title:'Web Programming',
        },
        {
          title:'Desktop application development',
        },
        {
          title:'Mobile application development',
        },
        {
          title:'Database design and optimization',
        },
        {
          title:'Data analysis and reports',
        },
        {
          title:'Computer science',
        },
        {
          title:'3D CAD modeling',
        },
        {
          title:'Algorithms',
        },
        {
          title:'Other(set your custom option)',
        },
      ],

      calculationSoftware:[
        {
          title:'Microsoft Excel',
        },
        {
          title:'Microsoft Word',
        },
        {
          title:'SPSS',
        },
        {
          title:'STATA',
        },
        {
          title:'SAS',
        },
        {
          title:'Other(set your custom option)',
        },
      ],


      calculationDiscipline:[
        {
          title:'Engineering',
        },
        {
          title:'Aviation',
        },
        {
          title:'Mathematics',
        },
        {
          title:'Statistics',
        },
        {
          title:'Chemistry',
        },
        {
          title:'Geology (and other earth sciences)',
        },
        {
          title:'Physics',
        },
        {
          title:'Astronomy (and other Space sciences)',
        },
        {
          title:'Geography',
        },
        {
          title:'Biology (and other life sciences)',
        },
        {
          title:'Ecology',
        },
        {
          title:'Zoology',
        },
        {
          title:'Finance',
        },
        {
          title:'Accounting',
        },
        {
          title:'Management',
        },
        {
          title:'Business Studies',
        },
        {
          title:'Medical sciences (Anatomy, Physiology, Pharmacology etc.)',
        },
        {
          title:'Architecture, Building and Planning',
        },
        {
          title:'Nursing',
        },
        {
          title:'Health Care',
        },
        {
          title:'Economics',
        },

        {
          title:'Other(set your custom option)',
        },
      ],

      ShadowHealthTypes:[
       
        {
          title:'Shadow Health',
          desc:'Shadow Health with SOAP Note',
          type:'shadow_soap',
        },
        {
          title:'Shadow Health',
          desc:'Shadow Health Only',
          type:'shadow'
        },
        // {
        //   title:'Shadow Health',
        //   desc:'SOAP Note Only',
        //   type:'shadow_soapnote_only',
        // }, 
        
        
      ],  

      ProgrammingTypes:[
       
       {
         title:'Top programming level',
         desc:'We assign top expert for your task',
         type:'1',
       },
       {
         title:'Well-commented code',
         desc:'You get all methods and functions in your code commented.',
         type:'2'
       },
       // {
       //   title:'Shadow Health',
       //   desc:'SOAP Note Only',
       //   type:'shadow_soapnote_only',
       // }, 
       
       
     ], 
      
      Type_of_paper:[
        {
          title:'Midterm exam',
          value:'Midterm exam',
        },
        {
          title:'Final exam',
          value:'Final exam'
        },
      ],
      Delivery_Time:[
        {
          title:'3 Days',
          value:'3',
        },
        {
          title:'5 Days',
          value:'5'
        },
        {
          title:'10 Days',
          value:'10'
        },
      ]
    };
  },
  async mounted() {
    this.$store.state.loading = true;
    await this._getOrder(this.$route.params.id)
      .then(() => (this.$store.state.loading = false))
      .catch(() => {
        this.$router.push({ name: "404" });
      });
    await this._getSetups();
    await this._getCoupon();
        await this.getWriters();

    this.$store.state.loading = false;
  },
  methods: {
    checkIfEmailInString(text) {
      var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      return re.test(text);
    },
    nextStep(step) {
      // validate title and description before going to the next step
      let canGoNext = true;

      if(this.orderForm.type == 'order'){

        if (this.orderForm.title == "" || this.orderForm.title == null) {
          this.errors.title = "Title field is required";
          canGoNext = false;
        } else if (this.orderForm.title.length < 8) {
          this.errors.title =
            "The title field should be greater than 7 characters";
          canGoNext = false;
        } else if (this.checkIfEmailInString(this.orderForm.title)) {
          this.errors.title = "The title field should not contain email address";
          canGoNext = false;
        } else {
          this.errors.title = "";
        }
        if (this.checkIfEmailInString(this.orderForm.description)) {
          this.errors.description =
            "The description field should not contain email address";
          canGoNext = false;
        } else if (
          this.orderForm.description == "" ||
          this.orderForm.description == null
        ) {
          this.errors.description = "The description field is required";
          canGoNext = false;
        } else {
          this.errors.description = "";
        }
        if (canGoNext && (step == 1 || step == 2 || step == 3)) {
          this.step = step;
        }
      }else{
        canGoNext = true;
        if (canGoNext && (step == 1 || step == 2 || step == 3)) {
          this.step = step;
        }
      }
    },


    changeOrderTypeForm(e){
      console.log("this is event of changeOrderTypeForm===>", e)
      this.orderForm_type = e.desc;
      this.ordertypeTitle = e.desc;
      this.orderForm.type = e.type;
      this.calculatePrice();

      if (this.orderForm.type == 'quiz' ) {
        // console.log("this is quiz")
        this.orderForm.description_extras.type = e.title
        console.log("this is quiz", this.orderForm.description_extras.type)

      } else {
        if (this.orderForm.type == 'ihuman' ) {
        // console.log("this is quiz")
          this.orderForm.description_extras.type = e.title
          console.log("this is ihuman", this.orderForm.description_extras.type)

        } else {

          if (this.orderForm.type == 'class' ) {
          // console.log("this is quiz")
            this.orderForm.description_extras.type = e.title
            console.log("this is class", this.orderForm.description_extras.type)

          } else {
            if (this.orderForm.type == 'order' ) {
            // console.log("this is quiz")
              this.orderForm.description_extras.type = e.title
              console.log("this is order", this.orderForm.description_extras.type)

            } else {
              if (this.orderForm.type == 'shadow' ) {
              // console.log("this is quiz")
                this.orderForm.type = 'shadow_soap';
                this.orderForm.description_extras.type = 'shadow_soap'
                console.log("this is order", this.orderForm.description_extras.type)

              } 
              // else {
                
              // }
              
            }
            
          }
          
        }
      }

    },

    changeAssignmentTypeForm(e){
      console.log("this is event of assignment_type===>", e)
      this.assignment_type = e.title;
      this.orderForm_type = e.title;
      this.orderForm.type = e.type;
      this.calculatePrice();


      // this.calculatePrice();

    },

    activateShadowHealth(e){
      console.log("this is event of activateShadowHealth===>", e)
      // this.orderForm_type = e.desc;
      this.ordertypeTitle = e.desc;

      this.orderForm.type = e.type;
      this.orderForm.description_extras.type = e.type;
      this.calculatePrice();


    },


    ...mapActions("setups", ["getSetups", "getCountries"]),
    ...mapActions("auth", ["doLogin", "doRegister"]),
    ...mapActions("order", ["_calculatePrice", "_updateOrder", "_placeOrder", "_getCoupon"]),
    ...mapActions("writers", ["getWriters"]),
    ...mapActions("order", [
      "_getOrder",
    ]),
    _getSetups() {
      this.getSetups()
        .then(() => {
          Object.assign(this.orderForm, { ...this.order });
          this.orderForm.type = this.order.type;
          this.orderForm.task_size = this.order?.special_order_extras?.task_size;
          this.orderForm.programming_language = this.order?.special_order_extras?.programming_language;
          this.orderForm.programming_category = this.order?.special_order_extras?.programming_category;
          this.orderForm.extras = this.order?.special_order_extras?.extras;
          this.orderForm.special_urgency = this.order?.special_order_extras?.urgency;
          this.orderForm.description_extras.school_email_username = this.order?.special_order_extras?.school_email_username;
          this.orderForm.description_extras.comments = this.order?.special_order_extras?.comments;
          this.orderForm.description_extras.school_password = this.order?.special_order_extras?.school_password;

          this.orderForm.description_extras.course_code = this.order?.special_order_extras?.course_code;
          this.orderForm.description_extras.course_name = this.order?.special_order_extras?.course_name;
          this.orderForm.description_extras.university = this.order?.special_order_extras?.university;

          this.orderForm.pages = this.order.no_pages;
          this.orderForm.slides = this.order.no_slides;
          this.orderForm.charts = this.order.no_charts;
          this.orderForm.additional_services =
          this.order.additional_services.map((item) => {
              if (item[0] >= 0) {
                return item[0];
              }
            });

          this.orderForm.files = [];
          delete this.orderForm.buttons;
          delete this.orderForm.client;
          delete this.orderForm.additional_services_text;
          this.calculatePrice();
        })
        .catch((err) => {
          console.log("getsetups", err);
          this.$router.push({ name: "503" });
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },

    activateAcademicLevel(level) {
      this.orderForm.academic_level = level.id;
      this.calculatePrice();
    },
    activateAcademicStyle(style) {
      this.orderForm.style = style.id;
    },
    activateLanguageStyle(style) {
      this.orderForm.language_style = style.id;
    },
    activateSpacing(space) {
      this.orderForm.spacing = space.id;
      this.calculatePrice();
    },
    activateWriterPref(pref) {
      this.orderForm.writer_level = pref.id;
      this.calculatePrice();
    },

    // activateExtras(pref) {
    //   this.orderForm.extras = pref.type;
    //   this.calculatePrice();
    // },

    activateExtras(pref) {
      const index = this.orderForm.extras.indexOf(pref.type);
      if (index === -1) {
        // If the item is not in the array, add it
        this.orderForm.extras.push(pref.type);
      } else {
        // If the item is already in the array, remove it
        this.orderForm.extras.splice(index, 1);
      }
      this.calculatePrice();
    },

    updatePages(option) {
      if (!option) {
        if (this.orderForm.pages > 0) {
          this.orderForm.pages--;
        }
      } else {
        this.orderForm.pages++;
        // if (this.orderForm.pages <= this.number_of_pages.max_pages) {
        // this.orderForm.pages++;
        // }
      }
      console.log("this.orderForm.pages", this.orderForm.pages);
      this.calculatePrice();
    },
    decrementSources() {
      if (this.orderForm.no_sources > 0) {
        this.orderForm.no_sources--;
      }
    },
    updateCharts(option) {
      if (!option) {
        if (this.orderForm.charts > 0) {
          this.orderForm.charts--;
        }
      } else {
        this.orderForm.charts++;
      }
      this.calculatePrice();
    },
    updateSlides(option) {
      if (!option) {
        if (this.orderForm.slides > 0) {
          this.orderForm.slides--;
        }
      } else {
        this.orderForm.slides++;
      }
      this.calculatePrice();
    },

    calculatePrice() {
      console.log("this.orderForm", this.orderForm);
      this.is_loading = true;
      this._calculatePrice(this.orderForm)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    uploadFiles(event) {
      let files = event.target.files;

      Object.keys(files).forEach((file) => {
        this.orderForm.files.push(files[file]);
      });
    },

    deleteFile(fileToDelete) {
      let files = this.orderForm.files.filter(
        (file) => file.name !== fileToDelete.name
      );
      this.orderForm.files = files;
    },

    async doPlaceOrder(action) {
      this.is_loading = true;
      console.log("orderForm no_sources", this.orderForm.no_sources);
      this._updateOrder(this.orderForm)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.$router.push(
              `/${process.env.VUE_APP_INSTALL_DIR}/home/order/${this.order.id}`
            );
            this.loading = false;
          } else {
            this.$notify({
              title: "Error",
              text: res.data
                ? res.data.Message
                : "Error while placing order, please try again",
              style: "danger",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => (this.is_loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>